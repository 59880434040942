import { createContext, ReactNode, useMemo, useState } from "react";
import { useGetMeQuery, User } from "./generated/graphql";
import { getGraphQLClient } from "./authConfig";
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

export const AlertContext = createContext<[React.Dispatch<React.SetStateAction<boolean>>, React.Dispatch<React.SetStateAction<string>>]>([() => false, () => ""]);
export const ReflectDataContext = createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>([false, () => false]);
export const CurrentUserContext = createContext<User | null>(null);
export const TokenContext = createContext<string>("");

const ProviderRoot = (props: { children?: ReactNode | undefined; }) => {
    const { children } = props;

    const [reflectData, setreflectData] = useState(false);
    const [openAlert, setopenAlert] = useState(false);
    const [alertMessage, setalertMessage] = useState("");
    const [currentUser, setcurrentUser] = useState<User | null>(null);

    useGetMeQuery({
        onCompleted: (data) => {
            setcurrentUser(data.Me as User);
        }
    });

    return (
        <CurrentUserContext.Provider value={currentUser}>
            <ReflectDataContext.Provider value={[reflectData, setreflectData]}>
                <AlertContext.Provider value={[setopenAlert, setalertMessage]}>
                    {children}
                </AlertContext.Provider>
            </ReflectDataContext.Provider>
        </CurrentUserContext.Provider>
    );
};

type IAppProviderProps = {
    children?: ReactNode | undefined;
};

const AppProvider = (props: IAppProviderProps) => {
    const { children } = props;
    const [client, setclient] = useState<null | ApolloClient<NormalizedCacheObject>>(null);
    const [token, settoken] = useState("");
    const auth0 = useAuth0();

    useMemo(() => {
        if (!auth0.isLoading && !auth0.isAuthenticated) {
            auth0.loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname,
                },
            });
        }
        if (!auth0.isLoading && auth0.isAuthenticated) {
            auth0.getAccessTokenSilently()
                .then(token => {
                    settoken(token);
                    setclient(getGraphQLClient(token));
                }).catch(function(rej) {
                    auth0.loginWithRedirect({
                        appState: {
                            returnTo: window.location.pathname,
                        },
                    });
                  });
        }
    }, [auth0, settoken, setclient]);
    return (
        <>
            {client ?
                <ApolloProvider client={client}>
                    <TokenContext.Provider value={token}>
                        <ProviderRoot>
                            {children}
                        </ProviderRoot>
                    </TokenContext.Provider>
                </ApolloProvider> :
                <></>
            }
        </>
    );
};
export default AppProvider;